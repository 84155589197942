import React from "react"

import Layout from "components/layout"
import SEO from "components/seo"
import Signature from "components/signature"
import { StorageContext, appStorage } from "contexts/storage/storage.context"

import '../styles/pages.scss'
import { useStaticQuery } from 'gatsby';

export const MyAge = () => (
  <>{
    useStaticQuery(graphql`
      query MyAgeQuery {
        site {
          siteMetadata {
            myAge
          }
        }
      }
    `).site.siteMetadata.myAge
  }</>
)

class IndexPage extends React.Component {

  constructor(props) {
    super(props)
  }

  get age() {
    return <MyAge />
  }

  render() {
    return (
      <StorageContext.Provider value={ appStorage }>
        <Layout>
          <SEO title="Home" />
          <div className="container">
            <div className="col description">
              <pre className="brush: json">
                <code>
{`const softwareEngineer = { 
  "name": "Erick", 
  "age": ${this.age}, 
  "from": "Goiânia, Brazil",
  "devSkills": [
    "JavaScript ES6",
    "Angular 2+",
    "Ionic 3 & 4",
    "StencilJS",
    "React",
    "Gatsby",
    "NodeJS"
  ]
}`}
                </code>
              </pre>
            </div>
            <div className="col figure">
              <Signature style={{
                transformOrigin: 'bottom right',
                transform: 'scale(.2)'
              }}/>
            </div>
          </div>
        </Layout>
      </StorageContext.Provider>
    )
  }

}

export default IndexPage
