import React from "react"
import PropTypes from "prop-types"
import { tl } from "services/animations/animation.service"
import { Power3 } from 'gsap'

/*
Tamanhos dos paths para 300px de largura
[
{ id: "path1", length: "458.71" }
{ id: "path2", length: "100.32" }
{ id: "path3", length: "8.10" }
{ id: "path4", length: "125.68" }
{ id: "path5", length: "36.69" }
{ id: "path6", length: "259.21" }
{ id: "path7", length: "32.80" }
{ id: "path8", length: "8.10" }
{ id: "path9", length: "201.03" }
{ id: "path10", length: "13.26" }
{ id: "path11", length: "8.10" }
]
*/

class Signature extends React.Component {
  componentDidMount() {
    const path = diff => `#erick-vieira-signature > g > #path${diff}`
    const ease = { ease: Power3.easeIn }
    const anim = { strokeDashoffset: 0 }
    setTimeout(() => {
      tl().timeScale(8)
/* E            */.to(path`1`, 3, { ...anim, ...ease }, path`1`)
/* ri           */.to(path`2`, 1, { ...anim }, path`2`+'+=.9')
/* pingo do i   */.to(path`3`, .5, { ...anim }, path`3`)
/* c k (começo) */.to(path`4`, 2.3, { ...anim, ...ease }, path`4`)
/* final do k   */.to(path`5`, 1.8, { ...anim }, path`5`+'+=2')
/* V            */.to(path`6`, 3, { ...anim, ...ease }, path`6`+'+=2')
/* i            */.to(path`7`, 1, { ...anim }, path`7`+'+=.8')
/* pingo do i   */.to(path`8`, .5, { ...anim }, path`8`)
/* eira         */.to(path`9`, 4, { ...anim }, path`9`+'+=.5')
/* perna do a   */.to(path`10`, .1, { ...anim }, path`10`)
/* pingo do i   */.to(path`11`, .5, { ...anim }, path`10`+'+=1')
    }, 500)
  }

  render() {
    return (
      <svg
        id="erick-vieira-signature"
        xmlns="http://www.w3.org/2000/svg"
        width="300"
        version="1.1"
        viewBox="0 0 264.905 161.304"
      >
        <g
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeWidth="3"
        >
          <path
            id="path1"
            strokeDasharray="458.71"
            strokeDashoffset="458.71"
            d="m 61.555641,51.091331 c -0.182002,4.592002 3.247,9.635001 7.309999,15.212001 -3.135999,-3.691 -11.225998,-5.072 -11.288001,1.110001 C 55.50164,54.513332 25.55764,99.744323 38.82164,110.41333 c 13.216001,10.63299 29.223001,-9.48301 54.741,-59.492999 4.564001,-8.942999 9.913,-22.716999 5.480001,-24.777999 -34.890001,62.975 -68.837002,111.706998 -83.351001,127.986998 -3.607,4.04599 -6.9700004,5.385 -9.1050004,1.09999 -5.495,-11.032 -2.852,-47.628 24.7040004,-84.452988"
          />
          <path
            id="path2"
            strokeDasharray="100.32"
            strokeDashoffset="100.32"
            d="m 69.489636,110.83494 8.739001,-20.615993 c 1.029999,-2.429999 -5.245001,0.448001 -2.173999,3.496003 1.834999,1.819998 3.609998,-1.59 5.529999,-2.252001 -1.66,10.321991 -1.686001,15.994001 -1.039,19.749991 2.994001,-0.84 7.875999,-11.421997 10.395001,-17.635992 -1.748001,5.278001 1.025999,26.815992 4.267998,13.525992"
          />
          <path
            id="path3"
            strokeDasharray="8.10"
            strokeDashoffset="8.10"
            d="m 93.678636,85.432948 c 0.412,0.806 -0.37,0.837 -0.566,0.656 -2.2,-2.035 0.86101,-3.982 3.456,-2.945"
          />
          <path
            id="path4"
            strokeDasharray="125.68"
            strokeDashoffset="125.68"
            d="m 99.441621,99.609943 c 1.858989,-2.919995 3.203979,-5.547994 5.946979,-6.714994 2.48,-1.055 4.077,3.630999 4.543,2.038 0.544,-1.856999 0.066,-9.447999 -4.009,-3.741999 -10.267965,14.38199 -6.122982,21.545 -3.347,20.45199 4.228,-1.66499 10.212,-17.76499 17.273,-32.587991 9.824,-20.623999 23.478,-36.869999 15.797,-20.998001"
          />
          <path
            id="path5"
            strokeDasharray="36.69"
            strokeDashoffset="36.69"
            d="m 125.9916,87.146948 c 3.18257,-6.159858 -8.009,0.567 -9.488,4.277 0.389,8.482995 4.096,14.199992 7.391,20.261992"
          />
          <path
            id="path6"
            strokeDasharray="259.21"
            strokeDashoffset="259.21"
            d="m 166.1046,56.307949 c -0.24,-1.024001 -0.468,-1.643001 0.267,-2.539002 13.718,-16.722999 26.843,-13.742998 24.505,-6.681998 -3.76,11.355999 -17.609,17.882999 -21.649,27.529999 -4.77,11.389 -2.282,20.995 3.876,36.614992 -0.32805,-21.322502 19.18698,-48.709412 25.39,-58.665992 27.213,-43.3260002 73.05884,-55.8545215 61.606,-47.172999"
          />
          <path
            id="path7"
            strokeDasharray="32.80"
            strokeDashoffset="32.80"
            d="m 184.9146,107.52162 c 0.33,-0.378 2.776,-7.01699 3.354,-9.969994 0.143,-0.726 0.788,-2.437 0.048,-2.22 -2.4,0.703 -2.713,17.500004 -0.52,16.819994 0.805,-0.24999 1.072,-0.368 1.843,-0.56699"
          />
          <path
            id="path8"
            strokeDasharray="8.10"
            strokeDashoffset="8.10"
            d="m 191.2856,90.217616 c 0.412,0.807 -0.37,0.838 -0.566,0.657 -2.2,-2.036 0.86,-3.983 3.456,-2.945"
          />
          <path
            id="path9"
            strokeDasharray="201.03"
            strokeDashoffset="201.03"
            d="m 192.4266,110.02562 c 2.583,-4.31499 6.213,-9.05999 6.851,-10.771994 0.638,-1.713 1.344,-3.902 0.945,-5.717 -0.823,-3.742 -5.64,6.121997 -5.15,10.063004 0.41,3.301 1.399,8.74199 4.11,8.50499 3.815,-0.334 6.221,-10.70999 9.308,-16.347994 0.142,4.804004 -0.218,15.499994 2.031,15.609994 3.297,0.163 8.298,-12.222994 9.592,-17.026994 0.436,-1.619 -3.278,-1.143 -1.795,1.984 1.782,3.762004 4.747,-0.91 7.512,-3.543 -1.326,13.793994 -1.515,20.998994 0.803,21.072004 1.63,0.051 4.603,-5.61401 6.838,-10.117 0.945,-1.904 1.757,-2.63 2.28,-4.199007 0.335,-1.002994 0.284,-2.787997 0.284,-2.787997 -2.131,5.183004 -1.478,11.539994 2.079,13.890994 2.426,1.60301 7.016,-9.13799 7.701,-14.599994 0.685,-5.46 -5.382,-4.22 -9.638,0.757"
          />
          <path
            id="path10"
            strokeDasharray="13.26"
            strokeDashoffset="13.26"
            d="m 243.8626,103.25963 c 2.009,4.06799 4.809,8.68999 9.822,6.14699"
          />
          <path
            id="path11"
            strokeDasharray="8.10"
            strokeDashoffset="8.10"
            d="m 210.0566,86.434616 c 0.677,0.6 -0.04,0.915 -0.288,0.817 -2.79,-1.093 -0.65,-4.022 2.145,-4.001"
          />
        </g>
      </svg>
    )
  }
}

Signature.propTypes = {
  size: PropTypes.string
}

Signature.defaultProps = {
  siteTitle: ``,
}

export default Signature
